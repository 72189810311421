var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-content',[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_vm._v(" 新建 ")]),_c('el-table',{attrs:{"data":_vm.tbData,"row-key":"id","expand-row-keys":_vm.expandRowIds,"tree-props":{ children: 'children', hasChildren: 'hasChildren' }}},[_c('el-table-column',{attrs:{"label":"组织架构层级","prop":"name"}}),_c('el-table-column',{attrs:{"label":"排序","prop":"order"}}),_c('el-table-column',{attrs:{"label":"备注","prop":"remarks"}}),_c('el-table-column',{attrs:{"label":"操作","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.expandRowIds[0] !== scope.row.id)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(scope.row)}}},[_vm._v(" 修改 ")]):_vm._e(),(_vm.expandRowIds[0] !== scope.row.id)?_c('el-button',{attrs:{"slot":"reference","type":"text"},on:{"click":function($event){return _vm.handleDelete(scope)}},slot:"reference"},[_vm._v(" 删除 ")]):_vm._e()]}}])})],1),_c('el-dialog',{attrs:{"title":_vm.form.id ? '修改部门' : '新增部门',"visible":_vm.visible,"close-on-click-modal":false,"width":"600px"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.handleClose}},[_c('el-form',{ref:"form",staticStyle:{"width":"400px","margin":"0 auto"},attrs:{"label-width":"80px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"上级部门","prop":"parentId","rules":[{ required: true, message: '请选择上级部门' }]}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.tbData,"props":{
            label: 'name',
            value: 'id',
            emitPath: false,
            checkStrictly: true,
          }},model:{value:(_vm.form.parentId),callback:function ($$v) {_vm.$set(_vm.form, "parentId", $$v)},expression:"form.parentId"}})],1),_c('el-form-item',{attrs:{"label":"部门名称","prop":"name","rules":[
          { required: true, message: '请输入部门名称' },
          { validator: _vm.noStartWithSpace },
        ]}},[_c('el-input',{attrs:{"maxlength":"30","placeholder":"请输入部门名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"排序","prop":"order","rules":[
          { required: true, message: '排序不能为空' },
          { pattern: /^[0-9]+$/, message: '只能输入数字' },
        ]}},[_c('el-input',{attrs:{"maxlength":"10","placeholder":"请输入"},model:{value:(_vm.form.order),callback:function ($$v) {_vm.$set(_vm.form, "order", $$v)},expression:"form.order"}})],1),_c('el-form-item',{attrs:{"label":"备注"}},[_c('el-input',{attrs:{"type":"textarea","rows":4,"resize":"none","maxlength":100,"placeholder":"请输入备注"},model:{value:(_vm.form.remarks),callback:function ($$v) {_vm.$set(_vm.form, "remarks", $$v)},expression:"form.remarks"}})],1)],1),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }