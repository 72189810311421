export function isExternal(path: string) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * 不能以空格开头
 * @param rule
 * @param value
 * @param callback
 */
export function noStartWithSpace(
  rule: any,
  value: string,
  callback: (err?: string) => {},
) {
  if (value.trim()) {
    callback();
  } else {
    callback('不能以空格开头');
  }
}
