<template>
  <main-content>
    <el-button
      type="primary"
      style="margin-bottom: 10px"
      @click="visible = true"
    >
      新建
    </el-button>
    <el-table
      :data="tbData"
      row-key="id"
      :expand-row-keys="expandRowIds"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column label="组织架构层级" prop="name" />
      <el-table-column label="排序" prop="order" />
      <el-table-column label="备注" prop="remarks" />
      <el-table-column label="操作" align="right">
        <template slot-scope="scope">
          <el-button
            v-if="expandRowIds[0] !== scope.row.id"
            type="text"
            @click="handleEdit(scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="expandRowIds[0] !== scope.row.id"
            slot="reference"
            type="text"
            @click="handleDelete(scope)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="form.id ? '修改部门' : '新增部门'"
      :visible.sync="visible"
      :close-on-click-modal="false"
      @close="handleClose"
      width="600px"
    >
      <el-form
        label-width="80px"
        :model="form"
        ref="form"
        style="width: 400px; margin: 0 auto"
      >
        <el-form-item
          label="上级部门"
          prop="parentId"
          :rules="[{ required: true, message: '请选择上级部门' }]"
        >
          <el-cascader
            style="width: 100%"
            v-model="form.parentId"
            :options="tbData"
            :props="{
              label: 'name',
              value: 'id',
              emitPath: false,
              checkStrictly: true,
            }"
          />
        </el-form-item>
        <el-form-item
          label="部门名称"
          prop="name"
          :rules="[
            { required: true, message: '请输入部门名称' },
            { validator: noStartWithSpace },
          ]"
        >
          <el-input
            v-model="form.name"
            maxlength="30"
            placeholder="请输入部门名称"
          />
        </el-form-item>
        <el-form-item
          label="排序"
          prop="order"
          :rules="[
            { required: true, message: '排序不能为空' },
            { pattern: /^[0-9]+$/, message: '只能输入数字' },
          ]"
        >
          <el-input v-model="form.order" maxlength="10" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="4"
            resize="none"
            :maxlength="100"
            v-model="form.remarks"
            placeholder="请输入备注"
          />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" :loading="loading" @click="handleSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </main-content>
</template>

<script>
import MainContent from '@/components/MainContent.vue';
import { deptApi } from '@/api';
import { DEPT_TYPE_TEXT } from '@/constants/user-status';
import { formatTree } from '@/utils/tools';
import { noStartWithSpace } from '@/utils/validate';
export default {
  components: { MainContent },
  data() {
    return {
      loading: false,
      DEPT_TYPE_TEXT,
      visible: false,
      tbData: [],
      expandRowIds: [],
      form: {
        id: null,
        parentId: null,
        name: '',
        order: '',
        remarks: '',
      },
    };
  },
  computed: {
    deptTypeOptions() {
      return Object.keys(DEPT_TYPE_TEXT).map(key => ({
        name: DEPT_TYPE_TEXT[key],
        value: key,
      }));
    },
  },
  created() {
    this.fetchAllDeptTree();
  },
  methods: {
    noStartWithSpace,
    fetchAllDeptTree() {
      deptApi.getDeptTree().then(res => {
        if (res.success) {
          this.tbData = formatTree([res.data]);
          this.expandRowIds = [res.data.id];
        }
      });
    },
    handleEdit(dept) {
      this.form = { ...dept };
      this.visible = true;
    },
    handleDelete(scope) {
      this.$confirm('确定删除当前部门吗?', '提示')
        .then(() => {
          return deptApi.deleteById(scope.row.id);
        })
        .then(() => {
          this.fetchAllDeptTree();
        })
        .catch(e => {
          console.log(e);
        });
    },
    handleClose() {
      this.$refs['form'].clearValidate();
      this.$refs['form'].resetFields();
      this.form = {
        id: null,
        parentId: null,
        name: '',
        order: '',
        remarks: '',
      };
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;
          const api = this.form.id ? deptApi.updateDept : deptApi.addDept;
          api(this.form).then(res => {
            this.loading = false;
            if (res.success) {
              this.$message.success(this.form.id ? '修改成功' : '新增成功');
              this.fetchAllDeptTree();
              this.visible = false;
            }
          }).finally(()=>{
            this.loading = false;
          });
        }
      });
    },
  },
};
</script>

<style></style>
